(function () {

    vf['social-sharing'] = {
        settings: {
            target: '.mod-social-sharing',
            windowParameters: 'width=600,height=350',
            fbShareURL: 'http://www.facebook.com/sharer.php?u=',
            twShareURL: 'http://www.twitter.com/share?url=',
            gpURL: 'https://plus.google.com/share?url=',
            linkedInURL: 'https://www.linkedin.com/shareArticle?mini=true&url='
        },

        init: function (context) {
            var s = vf['social-sharing'].settings;
            $(context).on('click', '.toggleSocialList', vf['social-sharing'].toggleSocialList);
            $(context).on('click', '.share-email', vf['social-sharing'].shareEmail);
            $(context).on('click', '.share-facebook', vf['social-sharing'].shareFacebook);
            $(context).on('click', '.share-twitter', vf['social-sharing'].shareTwitter);
            $(context).on('click', '.share-gplus', vf['social-sharing'].shareGooglePlus);
            $(context).on('click', '.share-linkedIn', vf['social-sharing'].shareLinkedIn);

            $(context).on('click', '.print-page', vf['social-sharing'].printPage);
            vf.dialog.init(context);
        },
        toggleSocialList: function (e) {
            e.preventDefault();
            var target = null;
            if (!$(this).hasClass('active')) {
                $(this).addClass('active');
                if ($(this).data('target')) { target = $(this).data('target'); }
                //Removing Old ShareEmail Overlay
                $('#shareEmailForm').remove();
                if (target && 0 < target.length) {
                    $(this).siblings('.' + target).append($('#social-sharing-list-main').html());
                    //init the email taf dialog
                    vf.dialog.init(document);
                    $('#shareEmailForm').html('<iframe src="https://' + window.location.hostname + '/taf/start?url=' + escape(document.location) + '" width="100%" height="100%"></iframe>');
                }
            } else {
                $(this).removeClass('active');

                if ($(this).data('target')) { target = $(this).data('target'); }
                if (target && 0 < target.length) {
                    $(this).siblings('.' + target).html('');
                }
            }
        },
        shareEmail: function (e) {
            e.preventDefault();
            var s = vf['social-sharing'].settings;
        },
        shareFacebook: function (e) {
            e.preventDefault();
            var s = vf['social-sharing'].settings;
            window.open(s.fbShareURL + encodeURIComponent(window.location.href), '', 'noreferrer' + s.windowParameters);
        },
        shareTwitter: function (e) {
            e.preventDefault();
            var s = vf['social-sharing'].settings;
            window.open(s.twShareURL + encodeURIComponent(window.location.href), '', 'noreferrer' + s.windowParameters);
        },
        shareGooglePlus: function (e) {
            e.preventDefault();
            var s = vf['social-sharing'].settings;
            window.open(s.gpURL + encodeURIComponent(window.location.href), '', 'noreferrer' + s.windowParameters);
        },
        shareLinkedIn: function (e) {
            e.preventDefault();
            var s = vf['social-sharing'].settings;
            window.open(s.linkedInURL + encodeURIComponent(window.location.href), '', 'noreferrer' + s.windowParameters);
        },
        printPage: function (e) {
            e.preventDefault();
            window.print();
        }

    };

}(vf));
