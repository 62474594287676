/**
 * JS Module for Device Chooser.
 * @returns {undefined}
 */
(function () {
    vf['device-chooser'] = {
        settings: {
            target: '.mod-device-chooser',
            backlinks: 'a.wm-backlink',
            nextlinks: 'li.active .cta-topic a'
        },
        init: function (context) {
            var s = this.settings;
            var self = this;
            /*
             * add class border-top to the first element for rounded
             * corners at the beginning of the list. Only mobile
             */
            var item = $('li.list-sml')[0];
            $(item).addClass('border-top');

        }
    };
}(vf));
